import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const gifData = [
    { src: 'baseJEFF.gif', loop: true, audio: '' },
    { src: 'party-transition.gif', loop: false, next: 'partyJEFF.gif', duration: 788, audio: 'click1.mp3'},
    { src: 'partyJEFF.gif', loop: true, audio: '' },
    { src: 'kazoo-transition.gif', loop: false, next: 'partyJEFF2.gif', duration: 1400, audio: 'kazoo.mp3' },
    { src: 'partyJEFF2.gif', loop: true, audio: '' },
    { src: 'right1-transition.gif', loop: false, next: 'right1.gif', duration: 1400, audio: 'fart3.mp3' },
    { src: 'right1.gif', loop: true, audio: '' },
    { src: 'left2-transition.gif', loop: false, next: 'left2.gif', duration: 700, audio: 'fart2.mp3' },
    { src: 'left2.gif', loop: true, audio: '' },
    { src: 'right3-transition.gif', loop: false, next: 'right3.gif', duration: 600, audio: 'fart1.mp3' },
    { src: 'right3.gif', loop: true, audio: '' },
    { src: 'left4-transition.gif', loop: false, next: 'left4.gif', duration: 600, audio: 'fart4.mp3' },
    { src: 'left4.gif', loop: true, audio: '' },
    { src: 'right5-transition.gif', loop: false, next: 'right5.gif', duration: 600, audio: 'fart3.mp3' },
    { src: 'right5.gif', loop: true, audio: '' },
    { src: 'left6-transition.gif', loop: false, next: 'left6.gif', duration: 600, audio: 'shortwetfart.mp3' },
    { src: 'left6.gif', loop: true, audio: '' },
    { src: 'right7-transition.gif', loop: false, next: 'final.gif', duration: 600, audio: 'longwetfart.mp3' },
    //{ src: 'right7first.gif', loop: false, next: 'final.gif', duration: 500, audio: '' },
    { src: 'final.gif', loop: true, audio: 'celebration.mp3' },
    // Add more GIFs and audio files here
  ];

  const [gif, setGif] = useState(gifData[0]);
  const audioRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [additionalGif, setAdditionalGif] = useState(null);
  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };


  const [portrait, setPortrait] = useState(false);

  const updateOrientation = () => {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    setPortrait(isPortrait);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleMouseEnter = (e, gifSrc) => {
    e.target.src = gifSrc;
  };

  const handleMouseLeave = (e, pngSrc) => {
    e.target.src = pngSrc;
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };



  useEffect(() => {
    updateOrientation(); // Initial check
    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);

    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);


  useEffect(() => {
    if (audioRef.current && gif.audio) {
      audioRef.current.src = gif.audio;
      audioRef.current.load();
      if (!firstLoad) {
        audioRef.current.play();
      } else {
        setFirstLoad(false);
      }
    }

    if (!gif.loop && gif.next) {
      const img = new Image();
      img.src = gif.next;
      img.onload = () => {
        setTimeout(() => {
          setGif(gifData.find((g) => g.src === gif.next));
          if (gif.src === 'right7-transition.gif') {
            setAdditionalGif(isMobile() ? 'longText-comp.gif' : 'longText-comp.gif');
          } /*else {S
            setAdditionalGif(null);
          }*/
        }, gif.duration);
      };
    }
  }, [gif, firstLoad]);

  const handleClick = () => {
    if (isMobile()) {
      navigator.vibrate(50); // Vibrate for 50 milliseconds
    }
  

    if (gif.src === 'final.gif') {
      openLink('https://app.manifold.xyz/c/JEFFniversary-1');
    } else if (gif.loop) {
      const currentIndex = gifData.findIndex((g) => g.src === gif.src);
      setGif(gifData[(currentIndex + 1) % gifData.length]);
    }
  };



  

  return (
    <div className="App"  style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: isMobile() && portrait ? "0" : isMobile() ? "90px" : "125px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "1%",
          zIndex: 3,
          background: "transparent",
          transition: "height 0.3s",
        }}
      >
        <img
          src="logo.gif"
          alt="Logo"
          className="logo"
          style={{
            height: isMobile() ? "80%" : "90%",
          }}
          onClick={reloadPage}
        />
        <img
          src="twitter.png"
          alt="Twitter"
          className="button"
          style={{
            marginLeft: "-1%",
            height: isMobile() ? "80%" : "90%",
          }}
          onMouseEnter={(e) => handleMouseEnter(e, "twitter.gif")}
          onMouseLeave={(e) => handleMouseLeave(e, "twitter.png")}
          onClick={() => openLink("https://twitter.com/JEFFTOWN_")}
        />
        <img
          src="opensea.png"
          alt="OpenSea"
          className="button"
          style={{
            marginLeft: "-3%",
            height: isMobile() ? "80%" : "90%",
          }}
          onMouseEnter={(e) => handleMouseEnter(e, "opensea.gif")}
          onMouseLeave={(e) => handleMouseLeave(e, "opensea.png")}
          onClick={() => openLink("https://opensea.io/collection/jeff-town")}
        />
      </header>
      {portrait && isMobile() && (
        <img
          src="rotate.gif"
          alt="Rotate Phone GIF"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            background: 'white',
            pointerEvents: 'none',
          }}
        />
      )}
    <img
  className="main-image"
  onClick={!isMobile() ? handleClick : null}
  onTouchEnd={isMobile() ? handleClick : null} // Add this line
  src={gif.src}
  alt="JEFF"
  style={{
    position: 'absolute',
    top: '50%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    scale: '95%',
  }}
  loop={gif.loop ? 'loop' : null}
/>
{additionalGif && (!isMobile() || !portrait) && (
  <img
    className="longtext-image"
    src={additionalGif}
    alt="Long Text GIF"
    style={{
      position: 'absolute',
      bottom: '4%',
      left: '48%',
      transform: 'translateX(-50%)',
      zIndex: 1,
      scale: '95%',
    }}
    />
  )
}
      <audio ref={audioRef} src={gif.audio}></audio>
    </div>
  );
}

export default App;